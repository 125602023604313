import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { black, white } from 'shared/colors';

const TermsOfUsePage = () => {
  return (
    <main className={css(styles.container)}>
      <div className={css(styles.textContainer)}>
        <h1 className={css(styles.header)}>TERMS OF USE</h1>

        <p className={css(styles.p)}>Last Updated: 10/2020</p>

        <h2 className={css(styles.h2)}>1. Acceptance of the Terms of Use</h2>
        <p className={css(styles.p)}>
          These terms of use are entered into by and between You and Caliva and
          its affiliated entities and brands (“<strong>Company</strong>,” “
          <strong>we</strong>,” or “<strong>us</strong>”). The following terms
          and conditions, together with any documents they expressly incorporate
          by reference (collectively, “<strong>Terms of Use</strong>”), govern
          your access to and use of any content, functionality, and services
          offered on or through websites or webpages linking to these Terms of
          Use (the “<strong>Website</strong>”), whether as a guest, customer, or
          a registered user.
        </p>
        <p className={css(styles.p)}>
          Please read these Terms of Use carefully before you start to use the
          Website
          <strong>
            . By using the Website or by clicking to accept or agree to the
            Terms of Use when this option is made available to you, you accept
            and agree to be bound and abide by these Terms of Use and our
            Privacy Policy, found at{' '}
            <a href="/privacy-policy" class="">
              caliva.com/privacy-policy
            </a>
            ,
          </strong>{' '}
          <strong>incorporated herein by reference.</strong> If you do not want
          to agree to these Terms of Use, you must not access or use the
          Website.
        </p>
        <p className={css(styles.p)}>
          THIS DOCUMENT CONTAINS IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND
          OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS, AND EXCLUSIONS THAT
          MIGHT APPLY TO YOU. PLEASE READ IT CAREFULLY. THESE TERMS OF USE
          REQUIRE YOU TO GIVE UP RIGHTS TO PARTICIPATE IN A CLASS ACTION OR
          REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM.
        </p>
        <p className={css(styles.p)}>
          The Website is offered and available to users who are 21 years of age
          or older and reside in the United States or any of its territories or
          possessions. By using the Website, you represent and warrant that you
          meet the foregoing eligibility requirements. If you do not meet these
          requirements, you must not access or use the Website.
        </p>

        <h2 className={css(styles.h2)}>2. Changes to the Terms of Use</h2>
        <p className={css(styles.p)}>
          We may revise and update these Terms of Use from time to time in our
          sole discretion. All changes are effective immediately when we post
          them and apply to all access to and use of the Website thereafter.
          Your continued use of the Website following the posting of revised
          Terms of Use means that you accept and agree to the changes. You are
          expected to check this page frequently so you are aware of any
          changes, as they are binding on you.
        </p>

        <h2 className={css(styles.h2)}>
          3. Accessing the Website and Account Security
        </h2>
        <p className={css(styles.p)}>
          We reserve the right to withdraw or amend the Website, and any service
          or material we provide on the Website, in our sole discretion without
          notice. We will not be liable if for any reason all or any part of the
          Website is unavailable at any time or for any period. From time to
          time, we may restrict access to some parts of the Website, or the
          entire Website.
        </p>
        <p className={css(styles.p)}>
          To purchase products or access some resources, you may be asked to
          provide certain registration details or other information. It is a
          condition of your use of the Website that all information you provide
          on the Website is correct, current, and complete. You agree that all
          information you provide to register with the Website or otherwise,
          including, but not limited to, through the use of any interactive
          features on the Website, is governed by our Privacy Policy, and you
          consent to all actions we take with respect to your information
          consistent with our Privacy Policy.
        </p>
        <p className={css(styles.p)}>
          If you choose, or are provided with, a username, password, or any
          other piece of information as part of our security procedures, you
          must treat such information as confidential, and you must not disclose
          it to any other person or entity. You also acknowledge that your
          account is personal to you and agree not to provide any other person
          with access to the Website or portions of it using your username,
          password, or other security information. You agree to notify us
          immediately of any unauthorized access to or use of your username or
          password or any other breach of security. You also agree to ensure
          that you exit from your account at the end of each session. You should
          use particular caution when accessing your account from a public or
          shared computer or network so that others are not able to view or
          record your password or other personal information.
        </p>
        <p className={css(styles.p)}>
          We have the right to disable any username, password, or other
          identifier, whether chosen by you or provided by us, at any time in
          our sole discretion for any or no reason, including if, in our
          opinion, you have violated any provision of these Terms of Use.
        </p>

        <h2 className={css(styles.h2)}>4. Intellectual Property Rights</h2>
        <p className={css(styles.p)}>
          The Website and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, audio, and the design, selection, and
          arrangement thereof) are owned by the Company, its licensors, or other
          third parties and are protected by United States and international
          copyright, trademark, patent, trade secret, and other intellectual
          property or proprietary rights laws.
        </p>
        <p className={css(styles.p)}>
          These Terms of Use permit you to use the Website for your personal use
          only. You must not reproduce, distribute, modify, create derivative
          works of, publicly display, publicly perform, republish, download,
          store, or transmit any material on our Website. This means that you
          may only view or download content from the Website for your own use
          and must not modify copies of any materials from the Website or delete
          or alter any copyright, trademark, or other proprietary rights notices
          from copies of materials from the Website.
        </p>
        <p className={css(styles.p)}>
          If you print, copy, modify, download, or otherwise use or provide any
          other person with access to any part of the Website in breach of these
          Terms of Use, your right to use the Website will stop immediately and
          you must, at our option, return or destroy any copies of the materials
          you have made. No right, title, or interest in or to the Website or
          any content on the Website is transferred to you, and all rights not
          expressly granted are reserved by the Company. Any use of the Website
          not expressly permitted by these Terms of Use is a breach of these
          Terms of Use and may violate copyright, trademark, and other laws.
        </p>

        <h2 className={css(styles.h2)}>5. Trademarks</h2>
        <p className={css(styles.p)}>
          The Company name and all related names, logos, product and service
          names, designs, and slogans are trademarks of the Company or its
          affiliates or licensors. You must not use such marks without the prior
          written permission of the Company. All other names, logos, product and
          service names, designs, and slogans on the Website are the trademarks
          of their respective owners.
        </p>

        <h2 className={css(styles.h2)}>6. Prohibited Uses</h2>
        <p className={css(styles.p)}>
          You may use the Website only for lawful purposes and in accordance
          with these Terms of Use. You agree not to use the Website:
        </p>
        <p className={css(styles.p)}>
          ·       In any way that violates any applicable federal, state, local,
          or international law or regulation (including, without limitation, any
          laws regarding the export of data or software to and from the US or
          other countries).
        </p>
        <p className={css(styles.p)}>
          ·       For the purpose of exploiting, harming, or attempting to
          exploit or harm minors in any way by exposing them to inappropriate
          content, asking for personally identifiable information, or otherwise.
        </p>
        <p className={css(styles.p)}>
          ·       To transmit, or procure the sending of, any advertising or
          promotional material without our prior written consent, including any
          “junk mail,” “chain letter,” “spam,” or any other similar
          solicitation.
        </p>
        <p className={css(styles.p)}>
          ·       To impersonate or attempt to impersonate the Company, a
          Company employee, another user, or any other person or entity
          (including, without limitation, by using email addresses associated
          with any of the foregoing).
        </p>
        <p className={css(styles.p)}>
          ·       To engage in any other conduct that restricts or inhibits
          anyone’s use or enjoyment of the Website, or which, as determined by
          us, may harm the Company or users of the Website, or expose them to
          liability.
        </p>
        <p className={css(styles.p)}>Additionally, you agree not to:</p>
        <p className={css(styles.p)}>
          ·       Use the Website in any manner that could disable, overburden,
          damage, or impair the Website or interfere with any other party’s use
          of the Website, including their ability to engage in real time
          activities through the Website.
        </p>
        <p className={css(styles.p)}>
          ·       Use any robot, spider, or other automatic device, process, or
          means to access the Website for any purpose, including monitoring or
          copying any of the material on the Website.
        </p>
        <p className={css(styles.p)}>
          ·       Use any manual process to monitor or copy any of the material
          on the Website, or for any other purpose not expressly authorized in
          these Terms of Use, without our prior written consent.
        </p>
        <p className={css(styles.p)}>
          ·       Use any device, software, or routine that interferes with the
          proper working of the Website.
        </p>
        <p className={css(styles.p)}>
          ·       Introduce any viruses, Trojan horses, worms, logic bombs, or
          other material that is malicious or technologically harmful.
        </p>
        <p className={css(styles.p)}>
          ·       Attempt to gain unauthorized access to, interfere with,
          damage, or disrupt any parts of the Website, the server on which the
          Website is stored, or any server, computer, or database connected to
          the Website.
        </p>
        <p className={css(styles.p)}>
          ·       Attack the Website via a denial-of-service attack or a
          distributed denial-of-service attack.
        </p>
        <p className={css(styles.p)}>
          ·       Otherwise attempt to interfere with the proper working of the
          Website.
        </p>

        <h2 className={css(styles.h2)}>7. Reliance on Information Posted</h2>
        <p className={css(styles.p)}>
          The information presented on or through the Website is made available
          solely for general information purposes. We do not warrant the
          accuracy, completeness, or usefulness of this information. Any
          reliance you place on such information is strictly at your own risk.
          We disclaim all liability and responsibility arising from any reliance
          placed on such materials by you or any other visitor to the Website,
          or by anyone who may be informed of any of its contents.
        </p>
        <p className={css(styles.p)}>
          The Website may include content provided by third parties. All
          statements or opinions expressed in these materials, and all articles
          and responses to questions and other content, other than the content
          provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </p>

        <h2 className={css(styles.h2)}>8. Changes to the Website</h2>
        <p className={css(styles.p)}>
          We may update the content on the Website from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Website may be out of date at any given time, and we are under
          no obligation to update such material.
        </p>

        <h2 className={css(styles.h2)}>
          9. Information About You and Your Visits to the Website
        </h2>
        <p className={css(styles.p)}>
          All information we collect on the Website is subject to our Privacy
          Policy. By using the Website, you consent to all actions taken by us
          with respect to your information in compliance with the Privacy
          Policy.
        </p>

        <h2 className={css(styles.h2)}>10. Terms of Sale</h2>
        <p className={css(styles.p)}>
          All purchases through our Website or other transactions for the sale
          of goods, services, or information formed through the Website, or
          resulting from visits made by you, are governed by this Terms of Sale
          section. Additional terms and conditions may also apply to specific
          portions, services, or features of the Website. All such additional
          terms and conditions are hereby incorporated by this reference.{' '}
          <strong>
            BY PLACING AN ORDER FOR PRODUCTS OR SERVICES FROM THE WEBSITE, YOU
            ACCEPT AND ARE BOUND BY THESE TERMS OF SALE.
          </strong>
        </p>
        <p className={css(styles.p)}>
          10.1.          Order Acceptance and Cancellation. You agree that your
          order is an offer to buy, under these terms, all products and services
          listed in your order. All orders must be accepted by us or we will not
          be obligated to sell the products or services to you. We may choose
          not to accept any orders in our sole discretion. After having received
          your order, we will send you a confirmation email with your order
          number and details of the items you have ordered. Acceptance of your
          order and the formation of the contract of sale between us and you
          will not take place unless and until you have received your order
          confirmation email. You have the option to cancel your order at any
          time before we have sent your order confirmation email by calling or
          emailing our Customer Support team.
        </p>
        <p className={css(styles.p)}>
          10.2          a. Prices posted on the Website are subject to change
          without notice. The price charged for a product or service will be the
          price in effect at the time the order is placed and will be set out in
          your order confirmation email. Price increases will only apply to
          orders placed after such changes. Posted prices do not include taxes
          or charges for shipping and handling. All such taxes and charges will
          be added to your merchandise total and will be itemized in your
          shopping cart and in your order confirmation email.
        </p>
        <p className={css(styles.p)}>
          {' '}
          b. Terms of payment are within our sole discretion and, unless
          otherwise agreed by us in writing, payment must be received by us
          before our acceptance of an order.
        </p>
        <p className={css(styles.p)}>
          10.3.       Shipments; Delivery; Title and Risk of Loss. You may be
          able to select whether to pick up product at our location or have
          product delivered to you. Please check the individual product page for
          specific delivery options. You will pay all shipping and handling
          charges specified during the ordering process. Title and risk of loss
          pass to you upon our transfer of the products to you. Shipping and
          delivery dates are estimates only and cannot be guaranteed. We are not
          liable for delays in delivery.
        </p>
        <p className={css(styles.p)}>
          10.4.       <strong>Returns and Refunds.</strong> WE OFFER NO REFUNDS
          ON PRODUCTS DESIGNATED AS NON-RETURNABLE OR FINAL SALE.
        </p>
        <p className={css(styles.p)}>
          a. Caliva.com. All sales are final with the exception of
          malfunctioning products or incorrect product delivery. For
          malfunctioning products, we have a 72-hour exchange policy. If you
          received a malfunctioning product or an incorrect product delivery,
          please send our Customer Support team or send us a message by using
          the chat icon on the bottom right corner of the Webpage.
        </p>
        <p className={css(styles.p)}>
          b. Calivacbd.com. Except for any products designated on the Website as
          non-returnable or final sale, we will accept a return of the products
          for a refund of your purchase price, less the original delivery and
          handling costs, provided such return is made within 14 days of
          delivery and provided such products are returned in their original
          condition, unopened, and with all original packaging intact. To return
          products, you must email us at
          <a href="mailto:cbdorders@calivacbd.com">
            cbdorders@calivacbd.com
          </a>{' '}
          for details on returning or exchanging your item. You are responsible
          for all handling charges on returned items. You bear the risk of loss
          during delivery. Returns must be shipped back with a tracking number.
          In-store returns of in-store or online purchases are accepted for
          EXCHANGE ONLY. Shipping fees are nonrefundable.
        </p>
        <p className={css(styles.p)}>
          c. Livezola.com. All sales are final. If there is any issue with your
          order, email{' '}
          <a href="mailto:Janine@livezola.com">Janine@livezola.com</a>.
        </p>
        <p className={css(styles.p)}>
          10.5.       <strong>Limitation of Liability.</strong> TO THE FULLEST
          EXTENT PROVIDED BY LAW, WE MAKE NO WARRANTY WHATSOEVER WITH RESPECT TO
          THE GOODS, INCLUDING ANY WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE,
          WHETHER ARISING BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE,
          USAGE OF TRADE, OR OTHERWISE.
        </p>

        <h2 className={css(styles.h2)}>
          11. Text messaging; Mobile Alert Program.
        </h2>
        <p className={css(styles.p)}>
          This section applies to users of Company’s Mobile Alert program. To
          participate in the program, users must be 21 years of age or older and
          must provide consent to receive text (SMS) messages, using automated
          dialing technology for marketing and informational purposes, at the
          telephone number provided by the user. The program may not be
          accessible from all mobile providers or compatible with all mobile
          devices. Consent to the program is not a condition of making a
          purchase from Company.
        </p>
        <p className={css(styles.p)}>
          Participants in the program will receive marketing messages or
          informational messages related to Company transactions on behalf of
          Company, from our SMS service provider. While Company does not charge
          for sending SMS messages, message and data charges from your mobile
          provider may apply.{' '}
        </p>
        <p className={css(styles.p)}>
          To unsubscribe from the Mobile Alert program, reply to any message
          with “STOP” or send us an email at info@gocaliva.com. Company will
          maintain a record of your unsubscribe request for five years as
          required by law. For questions about the Mobile Alert Program, please
          contact us at <a href="mailto:info@gocaliva.com">info@gocaliva.com</a>
          .<br />
          <br />
        </p>

        <h2 className={css(styles.h2)}>
          12. Linking to the Website and Social Media Features
        </h2>
        <p className={css(styles.p)}>
          You may link to our homepage, provided you do so in a way that is fair
          and legal and does not damage our reputation or take advantage of it,
          but you must not establish a link in such a way as to suggest any form
          of association, approval, or endorsement on our part without our
          express written consent. Subject to the foregoing, you must not:
        </p>
        <p className={css(styles.p)}>
          ·       Establish a link from any website that is not owned by you.
        </p>
        <p className={css(styles.p)}>
          ·       Cause the Website or portions of it to be displayed on, or
          appear to be displayed by, any other website, for example, framing,
          deep linking, or in-line linking.
        </p>
        <p className={css(styles.p)}>
          ·       Otherwise take any action with respect to the materials on the
          Website that is inconsistent with any other provision of these Terms
          of Use.
        </p>
        <p className={css(styles.p)}>
          You agree to cooperate with us in causing any unauthorized framing or
          linking immediately to stop. We reserve the right to withdraw linking
          permission without notice. We may disable all or any social media
          features and any links at any time without notice in our discretion.
        </p>

        <h2 className={css(styles.h2)}>13. Links from the Website</h2>
        <p className={css(styles.p)}>
          If the Website contains links to other sites and resources provided by
          third parties, these links are provided for your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. We have no control over the
          contents of those sites or resources, and accept no responsibility for
          them or for any loss or damage that may arise from your use of them.
          If you decide to access any of the third-party websites linked to the
          Website, you do so entirely at your own risk and subject to the terms
          and conditions of use for such websites.
        </p>

        <h2 className={css(styles.h2)}>14. Geographic Restrictions</h2>
        <p className={css(styles.p)}>
          The owner of the Website is based in the State of California in the
          United States. We provide the Website for use only by persons located
          in the United States. We make no claims that the Website or any of its
          content is accessible or appropriate outside of the United States.
          Access to the Website may not be legal by certain persons or in
          certain countries. If you access the Website from outside the United
          States, you do so on your own initiative and are responsible for
          compliance with local laws.
        </p>

        <h2 className={css(styles.h2)}>15. Disclaimer of Warranties</h2>
        <p className={css(styles.p)}>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our Website
          for any reconstruction of any lost data. TO THE FULLEST EXTENT
          PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED
          BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
          TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
          EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE
          TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON
          ANY WEBSITE LINKED TO IT.
        </p>
        <p className={css(styles.p)}>
          YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICE OR ITEM OBTAINED
          THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND
          ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN
          “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
          EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON
          ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH
          RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY,
          OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING,
          NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS
          OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE,
          OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE WEBSITE OR
          THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
          HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR
          EXPECTATIONS.
        </p>
        <p className={css(styles.p)}>
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
          ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p className={css(styles.p)}>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>

        <h2 className={css(styles.h2)}>16. Limitation on Liability</h2>
        <p className={css(styles.p)}>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
          ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
          UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH ANY GOODS
          OR SERVICE YOU PURCHASE THROUGH THE WEBSITE OR YOUR USE, OR INABILITY
          TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE
          WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT,
          SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT
          NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
          DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
          ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
          WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR
          OTHERWISE, EVEN IF FORESEEABLE.
        </p>
        <p className={css(styles.p)}>
          The limitation of liability set out above does not apply to liability
          resulting from our gross negligence or willful misconduct or death or
          bodily injury caused by products you purchase through the Website. THE
          FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
          LIMITED UNDER APPLICABLE LAW.
        </p>

        <h2 className={css(styles.h2)}>17. Indemnification</h2>
        <p className={css(styles.p)}>
          You agree to defend, indemnify, and hold harmless the Company, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys’ fees) arising out
          of or relating to your violation of these Terms of Use or your use of
          the Website, any use of the Website’s content, services, and products
          other than as expressly authorized in these Terms of Use, or your use
          of any information obtained from the Website.
        </p>

        <h2 className={css(styles.h2)}>18. Force Majeure. </h2>
        <p className={css(styles.p)}>
          We will not be liable or responsible to you, nor be deemed to have
          defaulted or breached these Terms of Use, for any failure or delay in
          our performance under these Terms of Use when and to the extent such
          failure or delay is caused by or results from acts or circumstances
          beyond our reasonable control, including, without limitation, acts of
          God, flood, fire, earthquake, explosion, governmental actions, war,
          invasion or hostilities (whether war is declared or not), terrorist
          threats or acts, riot or other civil unrest, national emergency,
          revolution, insurrection, epidemic, lockouts, strikes or other labor
          disputes (whether or not relating to our workforce), or restraints or
          delays affecting carriers or inability or delay in obtaining supplies
          of adequate or suitable materials, materials or telecommunication
          breakdown or power outage.
        </p>

        <h2 className={css(styles.h2)}>19. Governing Law and Jurisdiction</h2>
        <p className={css(styles.p)}>
          All matters relating to the Website and these Terms of Use, and any
          dispute or claim arising therefrom or related thereto (in each case,
          including non-contractual disputes or claims), shall be governed by
          and construed in accordance with the internal laws of the State of
          California without giving effect to any choice or conflict of law
          provision or rule (whether of the State of California or any other
          jurisdiction).
        </p>
        <p className={css(styles.p)}>
          Any legal suit, action, or proceeding arising out of, or related to,
          these Terms of Use or the Website shall be instituted exclusively in
          the federal courts of the United States or the courts of the State of
          California, in each case located in the City of San Jose and County of
          Santa Clara. You waive any and all objections to the exercise of
          jurisdiction over you by such courts and to venue in such courts.
        </p>

        <h2 className={css(styles.h2)}>20. Class Action Waiver</h2>
        <p className={css(styles.p)}>
          In any dispute, NEITHER YOU NOR COMPANY WILL BE ENTITLED TO JOIN OR
          CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS IN COURT OR IN
          ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS
          REPRESENTATIVE, CLASS MEMBER, OR IN A PRIVATE ATTORNEY GENERAL
          CAPACITY.
        </p>
        <p className={css(styles.p)}>
          BY USING THE WEBSITE OR PARTICIPATING IN COMPANY’S MOBILE ALERT
          PROGRAM, YOU AGREE TO GIVE UP ANY RIGHTS TO PARTICIPATE IN A CLASS
          ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM.{' '}
        </p>

        <h2 className={css(styles.h2)}>21. Waiver and Severability</h2>
        <p className={css(styles.p)}>
          No waiver by the Company of any term or condition set out in these
          Terms of Use shall be deemed a further or continuing waiver of such
          term or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
        </p>
        <p className={css(styles.p)}>
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal, or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </p>

        <h2 className={css(styles.h2)}>22. Entire Agreement</h2>
        <p className={css(styles.p)}>
          The Terms of Use, our Privacy Policy, and any documents they expressly
          incorporate by reference constitute the sole and entire agreement
          between you and Company regarding the Website and supersede all prior
          and contemporaneous understandings, agreements, representations, and
          warranties, both written and oral, regarding the Website.
        </p>

        <h2 className={css(styles.h2)}>23. Your Comments and Concerns</h2>
        <p className={css(styles.p)}>
          All feedback, comments, requests for technical support, and other
          communications relating to the Website should be directed to our
          Customer Support team.
        </p>
        <p></p>
      </div>
    </main>
  );
};

export default TermsOfUsePage;

const styles = StyleSheet.create({
  header: {
    fontSize: 24,
    paddingTop: 240,
  },
  container: {
    backgroundColor: black,
    color: white,
    padding: '0 20px',
  },
  textContainer: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    maxWidth: 856,
    margin: '0 auto',
    paddingBottom: 40,
  },
  h2: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    fontSize: 24,
    marginTop: 20,
  },
  p: {
    fontSize: 18,
    marginTop: 18,
  },
  ol: {
    paddingLeft: 20,
    paddingBottom: 50,
    margin: 0,
    fontSize: '12px',
  },
  li: {
    padding: 0,
    margin: '20px 0 0',
  },
});
